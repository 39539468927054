<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <tl-help-faq />
      </v-col>
      <v-col cols="12" md="6">
        <tl-help-guide />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlHelpFaq from './tl-help-faq'
import tlHelpGuide from './tl-help-guide'

export default {
  mixins: [toolbarMixin],
  components: {
    tlHelpFaq,
    tlHelpGuide,
  },
  head() {
    return {
      title: this.$t('help.title'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('help.description') }],
    }
  },
  computed: {
    tlToolbarTitle() {
      return this.$t('sidenav.help')
    },
  },
}
</script>
