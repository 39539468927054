<template>
  <div class="my-4 mx-2">
    <v-card class="mx-auto" max-width="600">
      <v-card-title class="d-block">
        <div v-t="'feedback.header'" />
        <div class="grey--text subtitle-1" v-t="'feedback.subhead'" />
      </v-card-title>

      <v-card-text class="text--primary">
        <v-radio-group v-model="directedTo" v-if="gym">
          <v-radio :label="$t('feedback.forGym', { gymName: gym.name })" value="gym" color="primary" />
          <v-radio :label="$t('feedback.forApp')" value="app" color="primary" />
        </v-radio-group>

        <p v-if="directedTo == 'gym' && gym">{{ $t('feedback.explanationGym', { gymName: gym.name }) }}</p>
        <p v-else>
          <span v-t="'feedback.explanation'" /><br />
          * <span class="md-caption" v-t="'feedback.gymFeedbackMsg'" />
        </p>

        <v-text-field v-model="message" :label="$t('feedback.inputLabel')" />
      </v-card-text>

      <v-card-actions>
        <v-btn text v-t="'generic.submit'" :loading="submitting" :disabled="submitting" @click="submit" />
      </v-card-actions>
    </v-card>

    <v-card v-if="feedbacks.length" class="mx-auto mt-4" max-width="600">
      <v-card-title class="d-block">
        <div v-t="'feedback.yourFeedback'" />
        <div class="grey--text subtitle-1" v-t="'feedback.yourFeedbackSub'" />
      </v-card-title>
      <v-timeline align-top dense>
        <tl-feedbacks-item v-for="feedback in feedbacks" :key="feedback.id" :feedback="feedback">
          <template v-slot:action>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" :loading="retracting_id == feedback.id" :disabled="retracting_id == feedback.id">
                  <v-icon>tl-more-vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="retract(feedback)">
                  <v-list-item-icon class="my-5"><v-icon v-text="'tl-delete'"/></v-list-item-icon>
                  <v-list-item-title v-t="'feedback.retract'" />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </tl-feedbacks-item>
      </v-timeline>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import User from '@/models/User'
import Feedback from '@/models/Feedback'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlFeedbacksItem from './tl-feedbacks-item'

export default {
  components: {
    tlFeedbacksItem,
  },
  mixins: [toolbarMixin],
  head() {
    return {
      title: this.$t('feedback.title'),
      meta: [{ vmid: 'description', name: 'description', content: this.$t('feedback.description') }],
    }
  },
  data: () => ({
    directedTo: 'app',
    message: '',
    submitting: false,
    retracting_id: null,
  }),
  computed: {
    ...mapState(['gym', 'user']),
    tlToolbarTitle() {
      return this.$t('sidenav.sendFeedback')
    },
    feedbacks() {
      return this.user.feedbacks
        .filter(f => !f.retracted)
        .slice()
        .sort((a, b) => b.created_at - a.created_at)
    },
  },
  created() {
    if (this.gym) this.directedTo = 'gym'
    this.fetchFeedbacks()
  },
  methods: {
    fetchFeedbacks() {
      if (this.user.guest) return
      User.$apiCall('feedbacks', { params: { user_id: this.user.id } }).then(feedbacks => {
        Feedback.inject(feedbacks)
      })
    },
    submit() {
      let feedback = { message: this.message }
      if (!this.user.guest) feedback.user_id = this.user.id
      if (this.directedTo == 'gym' && this.gym) feedback.gym_id = this.gym.id
      this.submitting = true
      Feedback.$apiCreate(feedback)
        .then(() => {
          this.message = ''
          this.$store.dispatch('toast/success', this.$t('feedback.successMsg'))
        })
        .finally(() => {
          this.submitting = false
        })
    },
    retract(feedback) {
      this.retracting_id = feedback.id
      feedback.$update({ retracted: true })
      feedback.$apiSave().finally(() => {
        this.retracting_id = null
      })
    },
  },
}
</script>
