<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline" v-t="'help.guide.header'" />
        <v-list-item-subtitle v-t="'help.guide.subhead'" />
      </v-list-item-content>
    </v-list-item>

    <v-card-text class="text--primary">
      <p v-t="'help.guide.intro'" />

      <h3 v-t="'help.guide.starting'" />
      <p v-t="'help.guide.startingExpl1'" />
      <p v-t="'help.guide.startingExpl2'" />

      <h3 v-t="'help.guide.climbing'" />
      <p v-t="'help.guide.climbingExpl'" />

      <h3 v-t="'help.guide.topping'" />
      <p v-t="'help.guide.toppingExpl'" />

      <h3 v-t="'help.guide.logging'" />
      <p v-t="'help.guide.loggingExpl'" />

      <ul>
        <li>
          <h5 v-html="loggingOs" />
          <p v-t="'help.guide.loggingOsExpl'" />
        </li>
        <li>
          <h5 v-html="loggingFl" />
          <p v-t="'help.guide.loggingFlExpl'" />
        </li>
        <li>
          <h5 v-html="loggingRp" />
          <p v-t="'help.guide.loggingRpExpl'" />
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    loggingFl() {
      return this.$t('help.guide.loggingFl')
    },
    loggingOs() {
      return this.$t('help.guide.loggingOs')
    },
    loggingRp() {
      return this.$t('help.guide.loggingRp')
    },
  },
}
</script>
