<template>
  <v-card>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline" v-t="'help.faq.header'" />
        <v-list-item-subtitle v-t="'help.faq.subhead'" />
      </v-list-item-content>
    </v-list-item>

    <div v-if="!gym || (gym.nr_of_routes && gym.nr_of_boulders)" class="d-flex align-center flex-wrap mx-4">
      <span class="text--bold">Type</span>
      <v-spacer />
      <v-btn-toggle v-model="climbType" mandatory tile group color="primary">
        <v-btn value="route">Routes</v-btn>
        <v-btn value="boulder">Boulders</v-btn>
      </v-btn-toggle>
    </div>

    <v-expansion-panels focusable accordion>
      <v-expansion-panel>
        <v-expansion-panel-header v-t="'help.faq.qGradeCalc'" />
        <v-expansion-panel-content class="pt-4">
          <p v-t="'help.faq.aGradeCalc'" />
          <v-btn
            text
            block
            color="primary"
            class="mb-4"
            v-t="'help.faq.showPoints'"
            @click="showGradesChartDialog(climbType)"
          />
          <p>
            <strong>{{ $t('help.faq.example') }} 1:</strong> <span v-t="'help.faq.aGradeCalcEx1'" />
          </p>
          <p>
            <strong>{{ $t('help.faq.example') }} 2:</strong> <span v-t="'help.faq.aGradeCalcEx2'" />
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="climbType == 'boulder'">
        <v-expansion-panel-header v-t="'help.faq.qTopFlash'" />
        <v-expansion-panel-content class="pt-4">
          <tl-help-logging-boulder />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="climbType == 'route'">
        <v-expansion-panel-header v-t="'help.faq.qTopFlashRp'" />
        <v-expansion-panel-content class="pt-4">
          <tl-help-logging-route />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="climbType == 'boulder'">
        <v-expansion-panel-header v-t="'help.faq.qCareTopFl'" />
        <v-expansion-panel-content class="pt-4">
          <p v-t="'help.faq.aCareTopFl'" />
          <p>
            <strong>{{ $t('help.faq.example') }} 1:</strong> <span translate v-t="'help.faq.aCareTopFlEx1'" />
          </p>
          <p>
            <strong>{{ $t('help.faq.example') }} 2:</strong> <span translate v-t="'help.faq.aCareTopFlEx2'" />
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="climbType == 'route'">
        <v-expansion-panel-header v-t="'help.faq.qCareTopFlRp'" />
        <v-expansion-panel-content class="pt-4">
          <p v-t="'help.faq.aCareTopFlRp'" />
          <p>
            <strong>{{ $t('help.faq.example') }} 1:</strong> <span translate v-t="'help.faq.aCareTopFlRpEx1'" />
          </p>
          <p>
            <strong>{{ $t('help.faq.example') }} 2:</strong> <span translate v-t="'help.faq.aCareTopFlRpEx2'" />
          </p>
          <p>
            <strong>{{ $t('help.faq.example') }} 3:</strong> <span translate v-t="'help.faq.aCareTopFlRpEx3'" />
          </p>
          <p>
            <strong>{{ $t('help.faq.example') }} 4:</strong> <span translate v-t="'help.faq.aCareTopFlRpEx4'" />
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header v-t="'help.faq.qScoreTime'" />
        <v-expansion-panel-content class="pt-4">
          <v-row>
            <v-col cols="4" class="font-weight-bold" v-t="'dashboard.ranking.gradeHeader'" />
            <v-col cols="8" v-html="$t('help.faq.aScoreTimeGrade')" />
          </v-row>
          <v-row>
            <v-col cols="4" class="font-weight-bold" v-t="'dashboard.ranking.countHeader'" />
            <v-col cols="8" v-html="$t('help.faq.aScoreTimeCount')" />
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header v-t="'help.faq.qGradeStability'" />
        <v-expansion-panel-content class="pt-4">
          <tl-help-logging-stability />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import tlGradesChartDialogMixin from '@/components/shared/tl-grades-chart/tl-grades-chart.dialog.mixin'
import tlHelpLoggingRoute from '@/components/extras/help/tl-help-logging-route'
import tlHelpLoggingBoulder from '@/components/extras/help/tl-help-logging-boulder'
import tlHelpLoggingStability from '@/components/extras/help/tl-help-logging-stability'

export default {
  mixins: [tlGradesChartDialogMixin],
  components: {
    tlHelpLoggingRoute,
    tlHelpLoggingBoulder,
    tlHelpLoggingStability,
  },
  computed: {
    ...mapState(['gym']),
    climbType: {
      get() {
        let fallbackType = this.gym && !this.gym.nr_of_routes ? 'boulder' : 'route'
        return this.$store.state.climbType || fallbackType
      },
      set(newType) {
        this.$store.commit('setClimbType', newType)
      },
    },
  },
}
</script>
